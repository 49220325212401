import React from 'react';
import './banner.scss';

class Banner extends React.Component {
  render () {
    return (
      <div className="banner container" id="accueil">
        <div className="row">
          <div className="col-lg-2 col-xs-12 border-right">
            <div className="container pt-4 d-none d-sm-block">
              <img src={process.env.PUBLIC_URL + "/images/logo_white.png"} alt="Total Technics" width="130"></img>
            </div>
            <div className="social d-flex d-sm-inline-flex flex-lg-column flex-md-row container justify-content-md-end">
              <a href="https://www.facebook.com/Total-Technics-2411328068889568/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
              <a href="https://www.linkedin.com/company/total-technics/about/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
              <a href="https://twitter.com/TechnicsTotal" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
            </div>
          </div>
          <div className="col-lg-6 col-xs-12">
            <div className="presentation">
              <h4>BIENVENUE A TOTAL TECHNICS</h4>
              <h2><i className="fa fa-minus"></i> <p>Génie Civil De Premier Ordre</p></h2>
              <p>
                Spécialisé dans les études et contrôle des travaux mettant en oeuvre les corps d'états dans le domaine de la construction
                (génie civil, courants forts, courants faibles, plomberie et sécurité incendie, climatisation-ventilation et désenfumage,
                voiries et réseaux divers, assainissement etc.). Il est doté d'un personnel qualifié pour répondre aux exigences du client.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-xs-12 pr-lg-0">
              <div className="features float-lg-right">
              <div className="container architecture shadow">
                <h3 className="text-center">Architecture</h3>
              </div>
              <div className="container genie_civil shadow">
                <h3 className="text-center">Génie Civil</h3>
              </div>
              <div className="container d_model shadow">
                <h4 className="text-center align-middle">Model 3D</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Banner;