import React from 'react';
import './teams.scss';

class Teams extends React.Component {
  render () {
    return (
      <div className="container teams mt-5 pt-5" id="equipe">
        <h3 className="text-center">NOTRE ÉQUIPE</h3>
        <p className="text-center mt-3">LES PERSONNES DERRIÈRE NOTRE SUCCÈS</p>
        <div className="row pt-4 pb-5">
          <div className="col-lg-4 col-md-6 col-xs-12">
            <h5 className="">Une équipe qualifiée composer:</h5>
            <ul className="sub-domaines">
              <li>
              <i className="fa fa-square icon"></i>
                d'Ingénieurs
              </li>
              <li>
              <i className="fa fa-square icon"></i>
                de Techniciens
              </li>
              <li>
              <i className="fa fa-square icon"></i>
                de Projeteurs et Dessinateurs
              </li>
              <li>
              <i className="fa fa-square icon"></i>
                d'Expert et de Consultants
              </li>
            </ul>
          </div>
          <div className="col-lg-8 col-md-6 col-xs-12 team-img">
          </div>
        </div>
      </div>
    )
  }
};

export default Teams;