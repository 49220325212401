import React from 'react';
import './footer.scss';

class Footer extends React.Component {
  render () {
    return (
      <div className="mt-5 footer bg-dark text-light text-center">
        <div className="container p-3">
          <div className="row">
           <div className="col-lg-4 col-xs-12">
            <p>© 2019 Total Technics. Tout Droit Réservée</p>
           </div>
           <div className="col-lg-5 col-xs-12">
            <ul className="nav justify-content-center text-light">
              <li className="nav-item">
                <a className="nav-link" href="#accueil">HOME</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#domaines">DOMAINES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">SERVICES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/mentions-legals">MENTIONS LÉGALS</a>
              </li>
            </ul>
           </div>
           <div className="col-lg-3 col-xs-12">
            <p>Powered by <a href={"https://burn-technology.com"} target="_blank" rel="noopener noreferrer">Burn-Technology</a></p>
           </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Footer;
