import React from 'react';
import './projects.scss';

class Projects extends React.Component {
  render () {
    return (
      <div className="container projects pt-5" id="portfolio">
        <h3 className="text-center">NOS PROJECTS RECENTS</h3>
        <div className="row mt-5">
          <div className="d-md-flex col-lg-4 col-md-12 col-xs-12 justify-content-center">
            <div className="card bonamoussadi">
              <div className="card-body">
                <h4 className="text-center justify-content-center">Stade de Bonamoussadi</h4>
                <p hidden>We deliver reliable architectural solutions to football staduim in Douala...</p>
                <a href="/" className="btn btn-dark"  hidden>VOIR PLUS</a>
              </div>
            </div>
          </div>
          <div className="d-md-flex col-lg-4 col-md-12 col-xs-12 justify-content-center">
            <div className="card bappelepe">
              <div className="card-body">
                <h4 className="text-center justify-content-center">Stade de Bappé leppé</h4>
                <p hidden>We deliver reliable architectural solutions to football staduim in Douala...</p>
                <a href="/" className="btn btn-dark" hidden>VOIR PLUS</a>
              </div>
            </div>
          </div>
          <div className="d-md-flex col-lg-4 col-md-12 col-xs-12 justify-content-center">
            <div className="card starland">
              <div className="card-body">
                <h4 className="text-center justify-content-center">Hotel starland</h4>
                <p hidden>We deliver reliable architectural solutions to football staduim in Douala...</p>
                <a href="/" className="btn btn-dark"  hidden>VOIR PLUS</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center mt-5 d-none"  hidden>
          <a href="/" className="btn btn-dark w-30 p-3 align-middle"> PLUS DE PROJETS</a>
        </div>
      </div>
    )
  }
};

export default Projects;