import React from 'react';
import './contact.scss';

class Contact extends React.Component {
  render () {
    return (
      <div className="container contact pt-5" id="contact">
        <h3 className="text-center">CONTACTEZ NOUS</h3>
        <div className="row pt-5">
          <div className="col-lg-6 col-md-12 detail">
            <h4>CONTACT</h4>
            <h6>Phone:  +237 694 975 721</h6>
            <h6>Email:  info@total-technics</h6>
            <h4>LOCALISATION</h4>
            <h6>Rond-Point Damas-Nsimeyong</h6>
            <div className="container-fluid p-0">
              <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.9722507515526!2d11.487055614749313!3d3.816071649754836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bd0305fb342ad%3A0xb2c73ae2d473b77d!2sRond+Point+Damase+%2CTradex!5e0!3m2!1sfr!2scm!4v1559633951118!5m2!1sfr!2scm"} frameBorder="0" style={{ border:0 }} allowFullScreen></iframe>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 form form">
           <h4 className="mb-3">PRENEZ CONTACT AVEC NOTRE EQUIPE</h4>
           <h5>TRAVAILLONS ENSEMBLE</h5>
           <div className="contanier bg-dark p-5">
            <form className="form-group mt-3 d-flex flex-column" action="mailto:contact@burn-technology.com" method="post" encType="text/plain">
              <input type="text" className="form-control mb-4" placeholder="Nom" name="Nom"/>
              <input type="email" className="form-control mb-4" placeholder="Email" name="Email"/>
              <input type="phone" className="form-control mb-4" placeholder="Téléphone" name="Telephone"/>
              <textarea rows="4" type="phone" className="form-control mb-4" placeholder="Message" name="Message">
              </textarea>
              <button className="btn btn-default mr-auto mt-4">ENVOYEZ</button>
            </form>
           </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Contact;