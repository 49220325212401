import React, { Component } from 'react';
import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }
  render() {
    const show = (this.state.menu) ? "show" : "" ;
    return (
      <div className="header row fixed-top bg-light shadow">
        <div className="container navbar navbar-expand-md navbar-light">
          <img className="d-block d-md-none ml-1" src={process.env.PUBLIC_URL + "/images/logo.png"} height="50" alt="total-technics"/>
          <button className="navbar-toggler mr-2" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
            aria-expanded="false" aria-label="Affiche ou masque la navigation" onClick={ this.toggleMenu }>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={"collapse navbar-collapse " + show}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="#accueil" data-scroll data-target="#accueil" className="nav-link">ACCUEIL</a>
              </li>
              <li className="nav-item">
                <a href="#domaines" data-scroll data-target="#domaines" className="nav-link">DOMAINES</a>
              </li>
              <li className="nav-item">
                <a href="#services" data-scroll data-target="#services" className="nav-link">SERVICES</a>
              </li>
              <li className="nav-item">
                <a href="#portfolio" data-scroll data-target="#porfolio" className="nav-link">PORFOLIO</a>
              </li>
              <li className="nav-item">
                <a href="#equipe" data-scroll data-target="#equipe" className="nav-link">EQUIPE</a>
              </li>
              <li className="nav-item d-none">
                <a href="/#partenaire" data-scroll data-target="#partenaire" className="nav-link">PARTENAIRES</a>
              </li>
              <li className="nav-item">
                <a href="#contact" data-scroll data-target="#contact" className="nav-link">CONTACT</a>
              </li>
          </ul>
        </div>
        </div>
      </div>
    );
  }
}

export default Header;