import React from 'react';
import './testimonials.scss';

class Testimonials extends React.Component {
  render () {
    return (
      <div className=" testimonial bg-dark p-5" hidden>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-xs-12">
              <span className="fa fa-quote-left quote"></span>
            </div>
            <div className="col-lg-10 col-xs-12 p-3">
              <div className="">
                <p className="text-break">I selected Total Technics because of thier architectct's rigorous design background
                and education. They exceeded my expectations ad did a great job on extending and redesigning my house.</p>
              </div>
              <div className="d-flex d-inline">
                <img src={process.env.PUBLIC_URL + '/images/manager.jpg'} width="120" height="100" alt="testimonial"></img>
                <div className="d-flex flex-column p-2">
                  <p className="text-light font-weight-bolder">Garet Thomson</p><br></br>
                  <span>Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Testimonials;