import React from 'react';
import './Domaines.scss';
class Domaines extends React.Component {
constructor(props) {
		super(props);
		this.state = {  }
}
render() {
	return (
	<div className="container py-5 domaines" id="domaines">
		<div className="row">
			<div className="col text-center">
				<h3>
					NOS DOMAINES
				</h3>
			</div>
		</div>
		<div className="row pt-5">
			<div className="col-md-6 col-lg-3">
				<h5 className="">ÉTUDE ROUTIERE & RÉSEAUX DIVERS</h5>
				<ul className="sub-domaines">
					<li>
					<i className="fa fa-square icon"></i>
						Voiries
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Routes & AutoRoute
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude de Plateformes
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Drainage & Assainissement
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Réseaux Extérieurs (Eaux Usées)
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Éclairage Public
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Déplacement des Réseaux Électriques & Télécom
					</li>
				</ul>
			</div>
			<div className="col-md-6 col-lg-3">
				<h5 className="">TOPOGRAPHIE</h5>
				<ul className="sub-domaines">
					<li>
					<i className="fa fa-square icon"></i>
						Lever Topographique
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Photogrammétrie A Base de Drones
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Réalisation de Modèles Numériques de Terrain (MNT)
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Calculs des Volumes à Base des Images Photogrammétriques
					</li>
				</ul>
			</div>
			<div className="col-md-6 col-lg-3">
				<h5 className="">GENIE CIVIL</h5>
				<ul className="sub-domaines">
					<li>
					<i className="fa fa-square icon"></i>
						Étude Structurale des Bâtiments
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude Structurale des Ouvrages du Génie Civil
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude d'Ouvrage d'Art
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude des Renforcement des Bâtiments de Génie Civil
						(Bâtiments et Ponts) à l'Aide des Matériaux Composites
					</li>
				</ul>
			</div>
			<div className="col-md-6 col-lg-3">
				<h5 className="">MEP</h5>
				<ul className="sub-domaines">
					<li>
					<i className="fa fa-square icon"></i>
						Étude Électricité CFO
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude Électricité CFA
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude Plomberie Sanitaire
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude Système de Sécurité Incendie, Protection Incendie et Désenfumage
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Étude Sonorisation
					</li>
				</ul>
			</div>
		</div>
		<div className="row">
			<div className="col-md-6 col-lg-3">
				<h5 className="">METHODOLOGIE</h5>
				<ul className="sub-domaines">
					<li>
					<i className="fa fa-square icon"></i>
						Mesure & Certification des Installations Électriques
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Certification Réseau LAN
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Mesure et Certification des Installations de Ventilation et Climatisation
					</li>
					<li>
					<i className="fa fa-square icon"></i>
						Tests, Épreuves et Rapports d'Essais Normatifs pour le Domaines MEP
					</li>
				</ul>
			</div>
			<div className="col-md-6 col-lg-3">
				<h5 className="">SUVIE DES CHANTIERS</h5>
				<ul className="sub-domaines">
				<li>
				<i className="fa fa-square icon"></i>
					Surveillance des Conditions de mise en Oeuvre des Ouvrages et Équipements
					Suivant les Règles de l'Art
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Controls de Sécurité
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Controls de Qualité et Conformité des Ouvrages et Équipements
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Planification
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Suivie Quantitatif des Ouvrages
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Rapports de Chantiers
				</li>
			</ul>
			</div>
			<div className="col-md-6 col-lg-3">
				<h5 className="">ÉVALUATIONS DES INFRASTRUCTURES</h5>
				<ul className="sub-domaines">
				<li>
				<i className="fa fa-square icon"></i>
					Évaluation Structurale des Bâtiments
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Évaluation Structurale des Ouvrages d'Art (Calcul des Capacités Portantes)
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Audit Énergétique
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Évaluation des Installation Électriques et Mécaniques
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Rédaction des Cahiers de Charges et Maintenance des Équipements
				</li>
				<li>
				<i className="fa fa-square icon"></i>
					Suivie de Maintenance
				</li>
			</ul>
			</div>
		</div>
	</div>
	);
}
}

export default Domaines;