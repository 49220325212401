import React from 'react';
import './service.scss';

class Services extends React.Component {
  render() {
    return(
      <div className="container services mt-4" id="services">
        <h2 className="text-center">NOS SERVICES</h2>
        <div className="row text-center text-justify mt-5">
          <div className="col">
            <h4>Assistance à Maîtrise d'Ouvrage (AMO)</h4>
          </div>
          <div className="col">
            <h4>Maîtrise d'Oeuvre Complète ou Partielle</h4>
          </div>
          <div className="col">
            <h4>Étude Technique dans Nos Domaines de Compétences</h4>
          </div>
          <div className="col">
            <h4>Mise à Disposition d'Experts</h4>
          </div>
        </div>
        <div className="row text-center text-justify">
        <div className="col">
            <h4>Mise à Disposition d'Experts</h4>
          </div>
          <div className="col">
            <h4>Audits Spécifiques</h4>
          </div>
          <div className="col">
            <h4>Étude d'Exécution pour les Entreprises</h4>
          </div>
          <div className="col">
            <h4>Conseils & Audits (Management & Opération)</h4>
          </div>
        </div>
      </div>
    )
  }
};

export default Services;