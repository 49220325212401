import './App.scss';
import Header from '../Header/Header';
import Banner from '../banner/banner';
import Services from '../services/services';
import './App.scss';
import React from 'react';
import './App.scss';
import Domaines from '../Domaines/Domaines';
import Projects from '../projects/projects';
import Teams from '../teams/teams';
import Testimonial from '../testimonials/testimonials';
import Contact from '../contact/contact';
import Footer from '../footer/footer';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return(
      <div className="app">
       <Header />
        <Banner />
        <Domaines />
        <Services />
        <Projects />
        <Teams />
        <Testimonial />
        <Contact />
        <Footer />
      </div>
    )
  }
}


export default App;